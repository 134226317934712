/* eslint-disable react/sort-comp */
import React from 'react';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { NavigateFunction } from 'react-router-dom';

import s from './InterestsTestScreen.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';
import AppContext, { AppContextType } from '../../context';
import {
  getGuestLocalStorageData,
  setGuestLocalStorageData,
} from '../Auth/withLogin';
import withAuthentication, {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../Auth/AuthenticationWrapper';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testIncompletionRequired from '../HomeScreen/DataWrappers/TestIncompletionRequiredWrapper';
import InterestCarousel from './InterestCarousel';
import Loading from '../Loading';
import { logTrackingData } from '../Layout';
import { TEST_MODE_OLD, TEST_MODE_YOUNG } from '../../constants/testModes';
import testDataYoung from '../../data/testDataYoung.json';
import testDataOld from '../../data/testDataOld.json';
import testDataYoungPilot2 from '../../data/testDataYoungPilot2.json';
import { withRouter } from '../../util/withRouter';

export const itestAnswersQuery = gql`
  query getUserItestAnswers {
    userItestAnswers
  }
`;

export const refetchItestAnswersQuery = async (
  context: AppContextType,
  forceNetwork = true,
) => {
  await context.client.query({
    query: itestAnswersQuery,
    fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
  });
};

export const resetTestMutation = gql`
  mutation resetTest {
    resetTest
  }
`;

const setItestAnswersMutation = gql`
  mutation setUserItestAnswers($itestAnswers: String!, $itestMode: String!) {
    setUserItestAnswers(itestAnswers: $itestAnswers, itestMode: $itestMode)
  }
`;

const finishTestMutation = gql`
  mutation finishUserTest {
    finishUserTest
  }
`;

const finishGuestTestQuery = gql`
  query finishGuestTest($itestAnswers: String!, $testMode: String!) {
    finishGuestTest(itestAnswers: $itestAnswers, testMode: $testMode)
  }
`;

const logTestFinishMutation = gql`
  mutation logTestFinish($mode: String!) {
    logTestFinish(mode: $mode) {
      success
      message
    }
  }
`;

const savePilotDataMutation = gql`
  mutation savePilotData($jsonData: String!) {
    savePilotData(jsonData: $jsonData)
  }
`;

interface InterestsTestScreenProps {
  location: Location;
  loggedMode: string;
  refetchItestResults: () => Promise<void>;
  userItestMode: string;
  navigate: NavigateFunction;
}

class InterestsTestScreen extends React.Component<InterestsTestScreenProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      userItestAnswers: null,
      userItestTimes: null,
      userItestWentBack: null,
      waitingToFinishTest: false,
      uploadStatus: null,
      canSubmit: true,
      canRestart: false,
    };

    this.logTestFinish = this.logTestFinish.bind(this);
    this.fetchItestAnswers = this.fetchItestAnswers.bind(this);
    this.loadAnswersForUser = this.loadAnswersForUser.bind(this);
    this.loadAnswersForGuest = this.loadAnswersForGuest.bind(this);
    this.setItestAnswers = this.setItestAnswers.bind(this);
    this.finishTest = this.finishTest.bind(this);
    this.finishPilotOrVerificationTest =
      this.finishPilotOrVerificationTest.bind(this);
    this.cancelTest = this.cancelTest.bind(this);
  }

  componentDidMount() {
    this.fetchItestAnswers(true);
  }

  async logTestFinish() {
    const { userItestMode } = this.props;

    await (this.context as AppContextType).client.mutate({
      mutation: logTestFinishMutation,
      variables: {
        mode: userItestMode,
      },
    });
  }

  // eslint-disable-next-line react/sort-comp
  async fetchItestAnswers(forceNetwork = true) {
    if (this.props.loggedMode === LOGGED_MODE_USER) {
      await this.loadAnswersForUser(forceNetwork);
    } else if (this.props.loggedMode === LOGGED_MODE_GUEST) {
      this.loadAnswersForGuest();
    }
  }

  // eslint-disable-next-line react/sort-comp
  async loadAnswersForUser(forceNetwork: boolean) {
    const response = await (this.context as AppContextType).client.query({
      query: itestAnswersQuery,
      fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
    });

    if (response && response.data && 'userItestAnswers' in response.data) {
      this.setState({
        loading: false,
        userItestAnswers:
          response.data.userItestAnswers &&
          response.data.userItestAnswers !== ''
            ? JSON.parse(response.data.userItestAnswers)
            : {},
      });
    }
  }

  loadAnswersForGuest() {
    const guestData = getGuestLocalStorageData();

    if (guestData) {
      this.setState({
        loading: false,
        userItestAnswers:
          guestData.userItestAnswers && guestData.userItestAnswers !== ''
            ? JSON.parse(guestData.userItestAnswers)
            : {},
        userItestTimes:
          guestData.userItestTimes && guestData.userItestTimes !== ''
            ? JSON.parse(guestData.userItestTimes)
            : {},
        userItestWentBack:
          guestData.userItestWentBack && guestData.userItestWentBack !== ''
            ? JSON.parse(guestData.userItestWentBack)
            : {},
      });
    }
  }

  async setItestAnswers(
    itestAnswers: any,
    itestTimes?: any,
    itestWentBack?: any,
  ) {
    const { location, userItestMode } = this.props;

    const isPilot = location.pathname.includes('normalization');
    const isVerification = location.pathname.includes('verification');

    logTrackingData(this.context as AppContextType);

    if (this.props.loggedMode === LOGGED_MODE_USER) {
      const response = await (this.context as AppContextType).client.mutate({
        mutation: setItestAnswersMutation,
        variables: {
          itestAnswers: JSON.stringify(itestAnswers),
          itestMode: userItestMode,
        },
      });

      return (
        response && response.data && response.data.setUserItestAnswers === true
      );
    }

    if (this.props.loggedMode === LOGGED_MODE_GUEST) {
      const guestData = getGuestLocalStorageData();

      const newGuestData =
        isPilot || isVerification
          ? {
              ...guestData,
              userItestMode: guestData.userItestMode || userItestMode,
              userItestAnswers: JSON.stringify(itestAnswers),
              userItestTimes: JSON.stringify(itestTimes),
              userItestWentBack: JSON.stringify(itestWentBack),
            }
          : {
              ...guestData,
              userItestMode: guestData.userItestMode || userItestMode,
              userItestAnswers: JSON.stringify(itestAnswers),
            };

      setGuestLocalStorageData(newGuestData);

      return true;
    }

    return false;
  }

  async finishTest(itestAnswers: any) {
    const { navigate } = this.props;

    this.setState({ waitingToFinishTest: true });

    let success = true;

    // handle logged user
    if (this.props.loggedMode === LOGGED_MODE_USER) {
      // make one final mutation call here and wait for it to ensure all answers are set.
      // (if for some reason, the previous call has been lost or has not yet been processed by the server)
      await this.setItestAnswers(itestAnswers);

      const response = await (this.context as AppContextType).client.mutate({
        mutation: finishTestMutation,
      });

      success = response.data && response.data.finishUserTest === true;
    }
    // handle guest user
    if (this.props.loggedMode === LOGGED_MODE_GUEST) {
      const guestData = getGuestLocalStorageData();

      const response = await (this.context as AppContextType).client.query({
        query: finishGuestTestQuery,
        variables: {
          itestAnswers: guestData.userItestAnswers,
          testMode: guestData.userItestMode || TEST_MODE_YOUNG,
        },
      });

      success = response.data && response.data.finishGuestTest;

      if (success) {
        const newGuestData = {
          ...guestData,
          userItestResults: response.data.finishGuestTest,
        };

        setGuestLocalStorageData(newGuestData);
      }
    }

    if (success) {
      // refetch test results and recommended professions for other screens
      await this.props.refetchItestResults();

      await this.logTestFinish();

      navigate(`${ROUTE_PREFIX}/home`);
    }
  }

  async finishPilotOrVerificationTest() {
    const { location, userItestMode } = this.props;

    const isPilot = location.pathname.includes('normalization');
    const isVerification = location.pathname.includes('verification');

    this.setState({
      uploadStatus: 'Daten werden hochgeladen...',
      canSubmit: false,
      canRestart: false,
    });

    const guestData = getGuestLocalStorageData();
    const userItestAnswers =
      guestData.userItestAnswers != null
        ? JSON.parse(guestData.userItestAnswers)
        : {};
    const userItestTimes =
      guestData.userItestTimes != null
        ? JSON.parse(guestData.userItestTimes)
        : {};
    const userItestWentBack =
      guestData.userItestWentBack != null
        ? JSON.parse(guestData.userItestWentBack)
        : {};

    let testData;
    if (isPilot) {
      testData = testDataYoungPilot2;
    } else if (isVerification) {
      testData = testDataYoungPilot2; // TODO - no verification data yet
    } else if (userItestMode === TEST_MODE_OLD) {
      testData = testDataOld;
    } else {
      testData = testDataYoung;
    }

    const insterestsData = testData.reduce((all: any, data: any) => {
      const id = `${data.id}`;
      // eslint-disable-next-line no-param-reassign
      all[id] = {
        interest: userItestAnswers[id] != null ? userItestAnswers[id] : -1,
        timeSpent: userItestTimes[id] != null ? userItestTimes[id] : 0,
        wentBack: !!userItestWentBack[id],
      };
      return all;
    }, {});

    let userCode;

    if (isPilot) {
      const normalizationData: any = JSON.parse(
        sessionStorage.getItem('normalization_data') || '{}',
      );

      if (normalizationData.code == null) {
        this.setState({
          uploadStatus: 'Fehler beim Auslesen des Personencodes.',
        });
        return;
      }

      userCode = normalizationData.code;
    } else if (isVerification) {
      userCode = 'Verification';
    } else {
      this.setState({
        uploadStatus: 'Invalider Pilot oder Verification Modus.',
      });
      return;
    }

    const publishResultData = {
      personCode: userCode,
      testMode: guestData.userItestMode,
      interests: insterestsData,
    };

    let result;

    try {
      result = await (this.context as AppContextType).client.mutate({
        mutation: savePilotDataMutation,
        variables: {
          jsonData: JSON.stringify(publishResultData),
        },
      });
    } catch (e) {
      result = null;
    }

    if (result?.data?.savePilotData === true) {
      this.setState({
        uploadStatus: 'Daten erfolgreich hochgeladen!',
        canSubmit: false,
        canRestart: true,
      });
    } else {
      this.setState({
        uploadStatus:
          'Beim Hochladen der Daten ist ein Fehler aufgetreten! Bitte klicke erneut auf "Abschließen".',
        canSubmit: true,
        canRestart: false,
      });
    }
  }

  async cancelTest() {
    const { location, navigate, refetchItestResults } = this.props;

    if (this.props.loggedMode === LOGGED_MODE_USER) {
      await (this.context as AppContextType).client.mutate({
        mutation: resetTestMutation,
      });
    } else if (this.props.loggedMode === LOGGED_MODE_GUEST) {
      const guestData = getGuestLocalStorageData();
      const newGuestData: any = {};

      // keep offers selection
      newGuestData.selectedBundesland = guestData.selectedBundesland;
      newGuestData.userAkooeData = guestData.userAkooeData;

      setGuestLocalStorageData(newGuestData);
    }

    const isPilot = location.pathname.includes('normalization');
    const isVerification = location.pathname.includes('verification');

    let url;
    if (isPilot) {
      url = `${ROUTE_PREFIX}/normalization`;
    } else if (isVerification) {
      url = `${ROUTE_PREFIX}/verification`;
    } else {
      url = `${ROUTE_PREFIX}/`;
    }
    navigate(url);

    // refetch test results for other screens
    await this.fetchItestAnswers();
    await refetchItestResults();
  }

  render() {
    const { location, userItestMode } = this.props;
    const {
      loading,
      userItestAnswers,
      userItestTimes,
      userItestWentBack,
      waitingToFinishTest,
      uploadStatus,
      canSubmit,
      canRestart,
    } = this.state;

    if (loading) return <Loading />;

    const isPilot = location.pathname.includes('normalization');
    const isVerification = location.pathname.includes('verification');

    return (
      <div className={s.interestsTestScreenContainer}>
        <div className={s.interestsTestScreenContainerInner}>
          <InterestCarousel
            navigate={this.props.navigate}
            setItestAnswers={this.setItestAnswers}
            finishTest={this.finishTest}
            finishPilotOrVerificationTest={this.finishPilotOrVerificationTest}
            initialItestAnswers={userItestAnswers}
            initialItestTimes={userItestTimes}
            initialItestWentBack={userItestWentBack}
            testMode={userItestMode}
            cancelTest={this.cancelTest}
            isPilot={isPilot}
            isVerification={isVerification}
            uploadStatus={uploadStatus}
            canSubmit={canSubmit}
            canRestart={canRestart}
          />
        </div>
        {waitingToFinishTest ? <Loading /> : null}
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  testIncompletionRequired,
  withRouter,
)(InterestsTestScreen);
