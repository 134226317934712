/* eslint-disable react/sort-comp */
import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { NavigateFunction } from 'react-router-dom';

import s from './InterestsTestScreen.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';
import ProgressTopBar from './ProgressTopBar';
import InterestCard from './InterestCard';
import SubmitCard from './SubmitCard';
import testDataYoung from '../../data/testDataYoung.json';
import testDataOld from '../../data/testDataOld.json';
import testDataYoungPilot2 from '../../data/testDataYoungPilot2.json';
import checkIfTouchDevice from '../../util/checkIfTouchDevice';
import SwipeableViews from './SwipeableViews';
import { TEST_MODE_OLD } from '../../constants/testModes';

const IMAGE_HINT_INTERVAL = 8000;
const IMAGE_HINT_DURATION = 500;

const SLIDER_CONTAINER_HEIGHT = 135;

interface InterestCarouselProps {
  navigate: NavigateFunction;
  setItestAnswers: (
    interestValues: any, // eslint-disable-line no-unused-vars
    interestTimes?: any, // eslint-disable-line no-unused-vars
    interestWentBack?: any, // eslint-disable-line no-unused-vars
  ) => void;
  // eslint-disable-next-line no-unused-vars
  finishTest: (interestValues: any) => void;
  finishPilotOrVerificationTest: () => void;
  initialItestAnswers?: any;
  initialItestTimes?: any;
  initialItestWentBack?: any;
  testMode: string;
  cancelTest: () => Promise<void>;
  isPilot?: boolean;
  isVerification?: boolean;
  uploadStatus?: string;
  canSubmit?: boolean;
  canRestart?: boolean;
}

class InterestCarousel extends React.Component<InterestCarouselProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  testData: any = null;

  imageHintInterval: any = null;

  imageHintPart1Timer: any = null;

  imageHintPart2Timer: any = null;

  timestamp: number = -1;

  static defaultProps = {
    initialItestAnswers: {},
    initialItestTimes: {},
    initialItestWentBack: {},
    isPilot: false,
    isVerification: false,
    uploadStatus: null,
    canSubmit: true,
    canRestart: false,
  };

  constructor(props: any) {
    super(props);

    if (props.isPilot) {
      this.testData = testDataYoungPilot2;
    } else if (props.isVerification) {
      this.testData = testDataYoungPilot2; // TODO - no verification data yet
    } else if (props.testMode === TEST_MODE_OLD) {
      this.testData = testDataOld;
    } else {
      this.testData = testDataYoung;
    }

    let startIndex = 0;

    // find first not answered question or last index if all questions have been answered
    if (props.initialItestAnswers) {
      for (let i = 0; i < this.testData.length; i += 1) {
        if (!(this.testData[i].id in props.initialItestAnswers)) {
          startIndex = i;
          break;
        } else if (i === this.testData.length - 1) {
          startIndex = i + 1;
        }
      }
    }

    if (startIndex === 0) {
      this.state = {
        interestValues: {},
        interestTimes: {},
        interestWentBack: {},
        newChanges: false,
        currentIndex: 0,
        highestIndex: 0,
        minIndex: 0,
        maxIndex: 0,
        showHint: false,
        animatedIndex: -1,
      };
    } else {
      this.state = {
        interestValues: props.initialItestAnswers,
        interestTimes: props.initialItestTimes,
        interestWentBack: props.initialItestWentBack,
        newChanges: false,
        currentIndex: Math.max(0, startIndex - 1),
        highestIndex: Math.max(0, startIndex - 1),
        minIndex: Math.max(0, startIndex - 2),
        maxIndex: startIndex,
      };
    }

    this.clearAllTimers = this.clearAllTimers.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.indexChanged = this.indexChanged.bind(this);
    this.measureTime = this.measureTime.bind(this);
    this.setInterestValue = this.setInterestValue.bind(this);
  }

  componentDidMount() {
    if (this.props.isPilot || this.props.isVerification) {
      this.timestamp = Date.now();
    }
  }

  componentWillUnmount() {
    this.clearAllTimers();
  }

  clearAllTimers() {
    if (this.imageHintInterval) {
      clearInterval(this.imageHintInterval);
    }
    if (this.imageHintPart1Timer) {
      clearTimeout(this.imageHintPart1Timer);
    }
    if (this.imageHintPart2Timer) {
      clearTimeout(this.imageHintPart2Timer);
    }
  }

  prevSlide() {
    const { currentIndex, minIndex } = this.state;

    if (currentIndex === 0) {
      this.props.navigate(`${ROUTE_PREFIX}/infographic`);
    }

    this.indexChanged(Math.max(minIndex, currentIndex - 1));
  }

  nextSlide() {
    const { currentIndex, maxIndex } = this.state;
    this.indexChanged(Math.min(maxIndex, currentIndex + 1));
  }

  indexChanged(newIndex: number) {
    const { isPilot, isVerification, uploadStatus } = this.props;

    if (uploadStatus != null) return;

    const {
      interestValues,
      interestTimes,
      interestWentBack,
      newChanges,
      currentIndex,
      highestIndex,
    } = this.state;

    const newHighestIndex = Math.max(highestIndex, newIndex);

    const newStateData: any = {
      newChanges: false,
      currentIndex: newIndex,
      highestIndex: newHighestIndex,
      minIndex: Math.max(0, newHighestIndex - 1),
      showHint: false,
      animatedIndex: -1,
    };

    if (isPilot || isVerification) {
      const ellapsedTime = this.measureTime();
      const newInterestTimes = { ...interestTimes };
      if (currentIndex < this.testData.length) {
        const currentInterestId = this.testData[currentIndex].id;
        newInterestTimes[currentInterestId] =
          (newInterestTimes[currentInterestId] || 0) + ellapsedTime;
      }

      const newInterestWentBack = { ...interestWentBack };
      if (newIndex < currentIndex) {
        const newInterestId = this.testData[newIndex].id;
        newInterestWentBack[newInterestId] = true;
      }

      this.props.setItestAnswers(
        interestValues,
        newInterestTimes,
        newInterestWentBack,
      );

      this.setState({
        ...newStateData,
        interestTimes: newInterestTimes,
        interestWentBack: newInterestWentBack,
      });
    } else {
      if (newChanges) {
        this.props.setItestAnswers(interestValues);
      }

      this.setState(newStateData);
    }

    this.clearAllTimers();
  }

  measureTime() {
    const now = Date.now();

    const ellapsedTime =
      this.timestamp !== -1 ? (now - this.timestamp) / 1000 : 0;

    this.timestamp = now;

    return ellapsedTime;
  }

  setInterestValue(index: number, value: number) {
    const { interestValues, maxIndex } = this.state;
    const newInterestValues = { ...interestValues };

    const interestId = this.testData[index].id;
    newInterestValues[interestId] = value;

    if (index === this.state.highestIndex) {
      this.clearAllTimers();

      this.imageHintInterval = setInterval(() => {
        this.setState({ showHint: true, animatedIndex: index });
        this.imageHintPart1Timer = setTimeout(() => {
          this.setState({ animatedIndex: index + 0.025 });
          this.imageHintPart2Timer = setTimeout(() => {
            this.setState({ showHint: false, animatedIndex: index });
          }, IMAGE_HINT_DURATION / 2);
        }, IMAGE_HINT_DURATION / 2);
      }, IMAGE_HINT_INTERVAL);
    }

    this.setState({
      interestValues: newInterestValues,
      newChanges: true,
      maxIndex: Math.max(maxIndex, index + 1),
    });
  }

  render() {
    const {
      testMode,
      finishTest,
      finishPilotOrVerificationTest,
      cancelTest,
      isPilot,
      isVerification,
      uploadStatus,
      canSubmit,
      canRestart,
    } = this.props;

    const {
      interestValues,
      currentIndex,
      highestIndex,
      minIndex,
      maxIndex,
      showHint,
      animatedIndex,
    } = this.state;

    const isTouchDevice = checkIfTouchDevice();

    return [
      !isTouchDevice ? (
        <div className={s.previousSlideButtonWrapper}>
          <button
            key="previousSlideButton"
            className={s.previousSlideButton}
            type="button"
            onClick={this.prevSlide}
            disabled={currentIndex !== 0 && currentIndex <= minIndex}
          >
            <div className={s.iconWrapper}>
              <FaArrowLeft />
            </div>
          </button>
        </div>
      ) : null,
      <div key="interestCarousel" className={s.interestCarousel}>
        <div className={s.carouselHeader}>
          <ProgressTopBar
            index={Math.min(currentIndex, this.testData.length - 1)}
            length={this.testData.length}
            cancelTest={cancelTest}
          />
        </div>
        <div className={s.carouselSlides}>
          <SwipeableViews
            index={showHint ? animatedIndex : currentIndex}
            indexmin={minIndex}
            indexmax={maxIndex}
            slidercontainerheight={SLIDER_CONTAINER_HEIGHT}
            onChangeIndex={this.indexChanged}
            disabled={uploadStatus != null}
          >
            {this.testData.map((interestData: any, index: number) => (
              <InterestCard
                key={`slide-${interestData.id}`} // eslint-disable-line react/no-array-index-key
                initialFade={index === 0 && maxIndex === 0} // special handling for first slide
                data={interestData}
                testMode={testMode}
                render={index >= currentIndex - 2 && index <= currentIndex + 2}
                showSlider={index >= highestIndex - 1 && index <= highestIndex}
                startValue={interestValues[this.testData[index].id] || -1}
                isPilot={isPilot}
                isVerification={isVerification}
                onValueChange={(value) => {
                  this.setInterestValue(index, value);
                }}
              />
            ))}
            <SubmitCard
              key="slide-submit"
              render={currentIndex >= this.testData.length - 2}
              finishTest={() => {
                if (isPilot || isVerification) {
                  finishPilotOrVerificationTest();
                } else {
                  finishTest(interestValues);
                }
              }}
              cancelTest={cancelTest}
              isPilot={isPilot}
              isVerification={isVerification}
              uploadStatus={uploadStatus}
              canSubmit={canSubmit}
              canRestart={canRestart}
            />
          </SwipeableViews>
        </div>
      </div>,
      !isTouchDevice ? (
        <div
          className={`${s.nextSlideButtonWrapper} ${
            showHint ? s.largeButton : null
          }`}
          data-tooltip-id="next-button-tooltip"
          data-tooltip-html={`
            Bitte gib vor dem Weiterklicken mit
            <br />
            dem Schieberegler unten an, in wieweit
            <br />
            dich diese Tätigkeit interessiert.
          `}
        >
          <button
            key="nextSlideButton"
            className={s.nextSlideButton}
            type="button"
            onClick={this.nextSlide}
            disabled={currentIndex >= maxIndex}
          >
            <div className={s.iconWrapper}>
              <FaArrowRight />
            </div>
          </button>
        </div>
      ) : null,
      !isTouchDevice && currentIndex >= maxIndex ? (
        <Tooltip id="next-button-tooltip" place="left" />
      ) : null,
    ];
  }
}

export default InterestCarousel;
